<template>
  <v-container  fluid>
    <!-- <v-row class="my-0 py-0">
      <v-col cols="2" class="my-0 py-0"></v-col>
      <v-col cols="4" class="my-0 py-0">
         <div ref="twitch-embed" id="twitch-embed-problematic"></div>
      </v-col>
      <v-col cols="4"  class="my-0 py-0">
        <div ref="twitch-embed" id="twitch-embed-zaphire"></div>
      </v-col>
      <v-col cols="2" class="my-0 py-0"></v-col>
    </v-row> -->
    <v-row class="my-0 py-0">
      <!-- <v-col>
        <div ref="twitch-embed" id="twitch-embed-zaphire"></div>
        <div ref="twitch-embed" id="twitch-embed-problematic"></div> -->
      <!-- <iframe frameborder="0"
                  scrolling="no"
                  id="chat_embed"
                  src="https://www.twitch.tv/embed/zaphireza/chat?parent=localhost"
                  height="500"
                  width="350">
          </iframe>
      </v-col> -->      
      <!-- <v-col class="my-0 py-0" cols="2">
        <iframe frameborder="0"
                  scrolling="no"
                  id="chat_embed"
                  src="https://www.twitch.tv/embed/problematiclive/chat?parent=omgtaplounge.com&parent=localhost"
                  height="500"
                  width="100%">
          </iframe>
      </v-col> -->
      <v-col class="my-0 py-0" cols="12" ref="unity-col-wrapper">
        <div ref="unity-container" id="unity-container" class="unity-desktop">
          <canvas ref="unity-canvas" id="unity-canvas"></canvas>
        </div>
        <div ref="loading-cover" id="loading-cover" style="display: none">
          <div ref="unity-loading-bar" id="unity-loading-bar">
            <div ref="unity-logo" id="unity-logo">
              <img src="../assets/OMG_tapper_trans.png" />
            </div>
            <div
              ref="unity-progress-bar-empty"
              id="unity-progress-bar-empty"
              style="display: none"
            >
              <div
                ref="unity-progress-bar-full"
                id="unity-progress-bar-full"
              ></div>
            </div>
            <div ref="spinner" id="spinner" class="spinner"></div>
          </div>
        </div>
        <div ref="unity-fullscreen-button" id="unity-fullscreen-button" style="display: none"></div>
      </v-col>
      <!-- <v-col class="my-0 py-0" cols="2">
        <iframe frameborder="0"
                  scrolling="no"
                  id="chat_embed"
                  src="https://www.twitch.tv/embed/zaphireza/chat?parent=omgtaplounge.com&parent=localhost"
                  height="500"
                  width="100%">
          </iframe>
      </v-col> -->
      <!-- </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
import router from "@/router";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";

var vueGame = {
  name: "Game",
  data: () => ({
    unityInstance: null,
    isLoaded: false,
    isConnected: false,
    connection: null,
    router: null,
  }),
  mounted() {    
    console.log("T:" + this.$router);
    this.router = this.$router;
    this.initialize();
    
    // var embed = new Twitch.Embed("twitch-embed-zaphire", {
    //   width: "100%",
    //   height: "300px",
    //   channel: "zaphireza",
    //   layout: "video",
    //   autoplay: false,
    //   // only needed if your site is also embedded on embed.example.com and othersite.example.com
    //   parent: ["omgtaplounge.com"],
    // });

    // var embed = new Twitch.Embed("twitch-embed-problematic", {
    //   width: "100%",
    //   height: "300px",
    //   channel: "problematiclive",
    //   layout: "video",
    //   autoplay: false,
    //   // only needed if your site is also embedded on embed.example.com and othersite.example.com
    //   parent: ["omgtaplounge.com"],
    // });
  },
  methods: {
    async signalRStart() {
      var that = this;
      this.connection = new HubConnectionBuilder()
        .withUrl("https://www.api.omgtaplounge.com:5423/gameserver")
        // .withUrl("https://localhost:44345/gameserver")
        .configureLogging(LogLevel.Information)
        .build();
      window.connection = this.connection;
      this.connection.on("ReceiveMessage", (message) => {
        that.unityInstance.SendMessage("GameManager", "WebOnMessage", message);
      });

      this.connection.onclose(() => {
        that.unityInstance.SendMessage("GameManager", "WebOnDisconnected");
        //that.startSignalR();
      });
      this.startSignalR();
    },
    startSignalR: async function () {
      try {
        await window.connection.start();
        console.log("SignalR Connected.");
        this.isConnected = true;
        this.unityInstance.SendMessage("GameManager", "WebOnConnected");

        this.unityInstance.SendMessage(
          "GameManager",
          "LoginWithWebsiteToken",
          this.$store.getters.token
        );
      } catch (err) {
        console.log(err);
        //setTimeout(this.startSignalR, 5000);
      }
    },
    webSocketStart() {
      var that = this;
      this.connection = new WebSocket("wss://api.omgtaplounge.com:3000");
      window.connection = this.connection;
      var that = this;
      this.connection.onmessage = function (event) {
        that.unityInstance.SendMessage(
          "GameManager",
          "WebOnMessage",
          event.data
        );
      };

      this.connection.onopen = function (event) {
        console.log("Successfully connected to the echo websocket server...");
        that.isConnected = true;
        that.unityInstance.SendMessage("GameManager", "WebOnConnected");

        that.unityInstance.SendMessage(
          "GameManager",
          "LoginWithWebsiteToken",
          that.$store.getters.token
        );
        //that.setUnityDetails();
        //that.websocketSend("CRASH BASTARD");
      };

      this.connection.onclose = function (event) {
        console.log("Connection closed");
        that.unityInstance.SendMessage("GameManager", "WebOnDisconnected");
      };

      this.connection.onerror = function (event) {
        console.log("Socket Error");
      };
    },
    websocketSend(message) {
      console.log("Sending message to server");
      window.connection.invoke("SendMessage", message);
      //window.connection.SendMessage("ReceiveMessage", message);
    },
    // websocketSend(message) {
    //   console.log("Sending message to server");
    //   window.connection.send(message);
    // },
    openLink(link) {
      switch (link) {
        case "discord":
          window.open("https://discord.gg/JjnAGnU7ur");
          break;
        case "nexus":
          router.push("/merch");
          break;
        case "merch":
          router.push("/merch");
          break;
        default:
        // code block
      }
    },
    initialize() {
      var that = this;
      const hideFullScreenButton = "";
      var buildUrl = "Build";
      var loaderUrl = buildUrl + "/WebGL.loader.js?v1";
      const config = {
        dataUrl: buildUrl + "/WebGL.data",
        frameworkUrl: buildUrl + "/WebGL.framework.js",
        codeUrl: buildUrl + "/WebGL.wasm",
        streamingAssetsUrl: "StreamingAssets",
        companyName: "DefaultCompany",
        productName: "OMG Tap Lounge",
        productVersion: "1.0",
      };

      const container = this.$refs["unity-container"];
      const canvas = this.$refs["unity-canvas"];
      const loadingCover = this.$refs["loading-cover"];
      const progressBarEmpty = this.$refs["unity-progress-bar-empty"];
      const progressBarFull = this.$refs["unity-progress-bar-full"];
      const fullscreenButton = this.$refs["unity-fullscreen-button"];
      const spinner = this.$refs["spinner"];

      const canFullscreen = (function () {
        for (const key of [
          "exitFullscreen",
          "webkitExitFullscreen",
          "webkitCancelFullScreen",
          "mozCancelFullScreen",
          "msExitFullscreen",
        ]) {
          if (key in document) {
            return true;
          }
        }
        return false;
      })();

      if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
        container.className = "unity-mobile";
        config.devicePixelRatio = 1;
      }
      loadingCover.style.display = "";

      const script = document.createElement("script");
      script.src = loaderUrl;
      script.onload = () => {
        createUnityInstance(canvas, config, (progress) => {          
          spinner.style.display = "none";
          progressBarEmpty.style.display = "";
          progressBarFull.style.width = `${100 * progress}%`;
        })
          .then((unityInstance) => {            
            loadingCover.style.display = "none";
            that.unityInstance = unityInstance;
            that.isLoaded = true;
            //that.webSocketStart();
            that.signalRStart();

            if (canFullscreen) {
              if (!hideFullScreenButton) {
                fullscreenButton.style.display = "";
              }
              fullscreenButton.onclick = () => {
                unityInstance.SetFullscreen(1);
              };
            }
          })
          .catch((message) => {
            alert(message);
          });
      };
      document.body.appendChild(script);
    },
  },
};

window.vueGame = vueGame;
export default vueGame;
</script>